const firebaseConfig = {
    apiKey: "AIzaSyC8fr_aJALLPdpeS5MTcG_M7aUtM9d1htA",
    authDomain: "joshuapinti-43545.firebaseapp.com",
    projectId: "joshuapinti-43545",
    storageBucket: "joshuapinti-43545.appspot.com",
    messagingSenderId: "759692779399",
    appId: "1:759692779399:web:b0a9b3a2a1d632acdcfd22",
    measurementId: "G-3WN153RD8J"
  };

export {firebaseConfig}